const Wistia = {
    init() {
        Wistia.chapterListActions();
    },

    chapterListActions() {
        var videos = document.querySelectorAll('.wp-block-wistia-video');
        window._wq = window._wq || [];

        videos.forEach(function (element) {
            var video_id = element.getAttribute('data-video-id');
            var show_chapter_list = element.getAttribute(
                'data-show-chapter-list'
            );

            if (show_chapter_list == 'true') {
                _wq.push({
                    id: video_id,
                    onReady: function (video) {
                        var chapters = element.querySelectorAll('#chapters li');
                        chapters.forEach(function (chapter) {
                            chapter.addEventListener('click', function () {
                                video
                                    .time(chapter.getAttribute('data-time'))
                                    .play();
                            });
                        });
                    },
                });
            }
        });
    },
};

Wistia.init();
